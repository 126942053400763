import { css } from "@emotion/core";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useRef } from "react";
import Img from "gatsby-image";
import MediaQuery, { useMediaQuery } from "react-responsive";
import BackgroundImage from "gatsby-background-image";
import styled from "@emotion/styled";
import { faArrowAltCircleDown } from "@fortawesome/free-regular-svg-icons";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

export default function Home() {
  const imgData = useStaticQuery(graphql`
    query MyQuery {
      heroImg: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(quality: 100, jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      maharielImg: file(relativePath: { eq: "mahariel.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      adventureImg: file(relativePath: { eq: "adventure.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chessImg: file(relativePath: { eq: "chess.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      divingImg: file(relativePath: { eq: "diving.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      yellowAbstractImg: file(relativePath: { eq: "yellow-abstract.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      glidingImg: file(relativePath: { eq: "gliding.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const adventureImgSrc = imgData.adventureImg.childImageSharp.fixed;
  const chessImgSrc = imgData.chessImg.childImageSharp.fixed;
  const divingImgSrc = imgData.divingImg.childImageSharp.fixed;
  const glidingImgSrc = imgData.glidingImg.childImageSharp.fixed;
  const yellowAbstractImgSrc = imgData.yellowAbstractImg.childImageSharp.fluid;
  const heroImg = imgData.heroImg.childImageSharp.fluid;
  const maharielImg = imgData.maharielImg.childImageSharp.fluid;

  const downArrowRef = useRef(null);

  const isSmallDevice = useMediaQuery({
    query: "(max-width: 1023px)",
  });

  const HeroTextContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: calc(90vh - 16px);
    @media (max-width: 768px) {
      width: 100%;
    }
  `;

  const HeroTextContent = (
    <>
      <h1
        css={css`
          font-size: 3rem;
          margin-bottom: 16px;
          text-align: center;
          @media (min-width: 1024px) {
            text-align: left;
            font-size: 4rem;
          }
        `}
      >
        Hi, I&apos;m Mahariel Rosario
      </h1>

      <div ref={downArrowRef}>
        <FontAwesomeIcon
          icon={faArrowAltCircleDown}
          css={css`
            color: white;
            font-size: 2.5rem;
          `}
        />
      </div>
    </>
  );

  useEffect(() => {
    const tl = gsap.timeline();
    gsap.registerPlugin(ScrollTrigger);
    tl.from(downArrowRef.current, {
      autoAlpha: 0,
      ease: "none",
      delay: 5,
    });
    tl.to(downArrowRef.current, {
      y: "+=10",
      duration: 0.5,
      yoyo: true,
      repeat: -1,
      delay: 15,
    });
    tl.to(downArrowRef.current, {
      y: "-=10",
      duration: 0.5,
      yoyo: true,
      repeat: -1,
      delay: 15,
    });
    if (!isSmallDevice) {
      tl.to(".abstractImg", {
        rotation: "180",
        width: "0vh",
        x: "50vw",
        height: "0vh",
        opacity: 0,
        scrollTrigger: {
          scrub: 1,
        },
        ease: "power3.out",
      });
    }
  }, []);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Learn about Mahariel Rosario" />
        <title>Mahariel Rosario</title>
      </Helmet>
      <BackgroundImage
        Tag="section"
        fluid={heroImg}
        css={css`
          display: flex;
          height: 100vh;
          color: #fca311;
          @media (max-width: 1023px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        `}
      >
        <MediaQuery maxWidth={1023}>
          <HeroTextContainer>{HeroTextContent}</HeroTextContainer>
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <Img
            fluid={maharielImg}
            css={css`
              display: inline-flex;
              width: 40vw;
              @media (min-width: 1440px) {
                width: 28vw;
              }
            `}
          />
          <div
            css={css`
              display: inline-flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 60vw;
              @media (min-width: 1440px) {
                width: 72vw;
              }
            `}
          >
            <div
              css={css`
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;
              `}
            >
              <HeroTextContainer>{HeroTextContent}</HeroTextContainer>
            </div>
          </div>
        </MediaQuery>
      </BackgroundImage>
      <Img
        fluid={yellowAbstractImgSrc}
        css={css`
          height: 100vh;
        `}
        className="abstractImg"
      />

      <section
        css={css`
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          flex-direction: column;
          text-align: center;
        `}
        className="work-section gray-bkgd"
      >
        <h2
          css={css`
            font-size: 2.5rem;
          `}
        >
          Uniting creativity and technology through front end development drives
          my <span className="orange-font">ambition</span>
        </h2>
      </section>

      <section
        className="testimonials"
        css={css`
          margin: 0 16px;
          height: 100%;
          text-align: left;
        `}
      >
        <h2
          css={css`
            text-align: center;
            font-size: 2.5rem;
          `}
        >
          I believe greatness can happen when you follow your passions{" "}
        </h2>
        <section
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <FontAwesomeIcon
            icon={faQuoteLeft}
            size="lg"
            className="orange-font"
            css={css`
              font-size: 3rem;
              align-self: flex-start;
            `}
          />
          <blockquote>
            Mahariel takes his work very seriously and could turn a design into
            &quot;code ready&quot; work within minutes of receiving it. Although
            Mahariel is a fast learner, he is very careful and cautious about
            his work. That means he repeatedly runs checks on his programs (and
            others) before making them public to ensure there aren&apos;t any
            hiccups. His quick thinking and cautious personality have helped the
            company avoid some costly and embarrassing mistakes. <br /> <br />{" "}
            Mahariel would be an asset to have on any team. He pitches
            innovative ideas during standups but also listens to what others
            have to say, including constructive criticism. He is a credit to his
            teammates.
            <p
              css={css`
                font-weight: bold;
              `}
            >
              Joan, Senior Designer
            </p>
          </blockquote>
          <FontAwesomeIcon
            icon={faQuoteRight}
            size="lg"
            className="orange-font"
            css={css`
              font-size: 3rem;
              align-self: flex-start;
            `}
          />
        </section>
        <section
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <FontAwesomeIcon
            icon={faQuoteLeft}
            size="lg"
            className="orange-font"
            css={css`
              font-size: 3rem;
              align-self: flex-start;
            `}
          />
          <blockquote>
            Mahariel was a pleasure to work with and consistently went above and
            beyond. When faced with daunting challenges, he always brought great
            solutions to complex problems, with a positive, can do attitude. I
            knew I could always count on Mahariel to get the job done and he
            would stay on it until completed.
            <p
              css={css`
                font-weight: bold;
              `}
            >
              Nicolaus, Senior Product Manager
            </p>
          </blockquote>
          <FontAwesomeIcon
            icon={faQuoteRight}
            size="lg"
            className="orange-font"
            css={css`
              font-size: 3rem;
              align-self: flex-start;
            `}
          />
        </section>
      </section>
      <section
        css={css`
          height: 100%;
          color: white;
          padding: 0 16px;
        `}
        className="interests blue-bkgd"
      >
        <h2
          css={css`
            font-size: 2.5rem;
            text-align: center;
            margin-top: 0;
            padding-top: 16px;
          `}
        >
          Outside of work, I enjoy...
        </h2>
        <section
          css={css`
            display: flex;
            height: inherit;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            padding-bottom: 16px;
          `}
        >
          <div
            css={css`
              position: relative;
            `}
          >
            <Img className="gatsby-img" fixed={adventureImgSrc} />
            <div className="img-overlay">
              <p>Adventure</p>
            </div>
          </div>
          <div
            css={css`
              position: relative;
            `}
          >
            <Img className="gatsby-img" fixed={chessImgSrc} />
            <div className="img-overlay">
              <p>Chess</p>
            </div>
          </div>
          <div
            css={css`
              position: relative;
            `}
          >
            <Img className="gatsby-img" fixed={divingImgSrc} />
            <div className="img-overlay">
              <p>Diving</p>
            </div>
          </div>
          <div
            css={css`
              position: relative;
            `}
          >
            <Img className="gatsby-img" fixed={glidingImgSrc} />
            <div className="img-overlay">
              <p>Gliding</p>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
}
