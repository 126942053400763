import styled from "@emotion/styled";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedinIn,
  faTwitter,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { css } from "@emotion/core";

const FooterElem = styled.footer`
  background-color: #fca311;
  height: 100%;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 767px) {
    align-items: center;
    justify-content: space-evenly;
  }
`;

const commonIconStyles = css`
  margin-left: 16px;
  margin-right: 16px;
  font-size: 2rem;
`;

const Footer = () => (
  <FooterElem>
    <p>Follow your dreams, work hard, and become your best self.</p>
    <p>
      {" "}
      Contact me at
      <a
        css={css`
          color: white;
        `}
        href="mailto:mahariel@maharielrosar.io"
      >
        {" "}
        mahariel@maharielrosar.io
      </a>
    </p>
    <div
      css={css`
        box-shadow: 1px 1px 0px 6px white;
        border-radius: 20px;
        @media (max-width: 1024px) {
          text-align: center;
          width: 100%;
          border-radius: 0px;
        }
        background-color: white;
      `}
    >
      <FontAwesomeIcon
        icon={faFacebook}
        css={css`
          ${commonIconStyles};
          color: #3351a5;
        `}
        className="indigo hover-styles"
        onClick={() => {
          window.open("https://www.facebook.com/mr0sari0h", "_blank");
        }}
      />
      <FontAwesomeIcon
        icon={faTwitter}
        className="hover-styles"
        css={css`
          ${commonIconStyles};
          color: #00aced;
        `}
        onClick={() => {
          window.open("https://twitter.com/mr0sari0h", "_blank");
        }}
      />
      <FontAwesomeIcon
        icon={faLinkedinIn}
        css={css`
          ${commonIconStyles};
          color: #0073b1;
        `}
        className="hover-styles"
        onClick={() => {
          window.open("https://www.linkedin.com/in/mahariel/", "_blank");
        }}
      />
      <FontAwesomeIcon
        icon={faGithub}
        css={css`
          ${commonIconStyles};
          color: #000;
        `}
        className="hover-styles"
        onClick={() => {
          window.open("https://github.com/mr0sari0", "_blank");
        }}
      />
    </div>
  </FooterElem>
);

export default Footer;
