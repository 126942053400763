import { css, Global } from "@emotion/core";
import React from "react";
import Footer from "./Footer";

const orangeFontColor = "#fca311";

const Layout = ({ children }) => (
  <main>
    <Global
      styles={css`
        :root {
          --poiret-one-font: "Poiret One", cursive;
        }

        html,
        body {
          margin: 0;
          color: #000;
          font-size: 20px;
          font-family: var(--poiret-one-font);
        }

        p {
          margin-left: 8px;
          margin-right: 8px;
        }

        a {
          text-decoration: none;
        }

        .hover-styles {
          color: #fff;
          &:hover {
            color: ${orangeFontColor};
            cursor: pointer;
          }
        }

        .gatsby-img {
          margin: 16px;
        }

        .img-overlay {
          position: absolute;
          top: 16px;
          left: 16px;
          width: 300px;
          height: 300px;
          background: rgba(0, 0, 0, 0.6);
          color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: opacity 0.25s;
          font-size: 2rem;
        }

        .img-overlay > * {
          transform: translateY(50px);
          transition: transform 0.25s;
        }

        .img-overlay:hover {
          opacity: 1;
          box-shadow: 1px 1px 17px 21px ${orangeFontColor};
        }

        .img-overlay:hover > * {
          transform: translateY(0);
        }

        .work-section {
          padding: 16px;
        }

        .gatsby-img:hover {
          box-shadow: 1px 1px ${orangeFontColor};
        }

        .gray-bkgd {
          background-color: #e5e5e5;
        }

        .blue-bkgd {
          background-color: #14213d;
        }

        .orange-font {
          color: ${orangeFontColor};
        }
      `}
    />
    {children}
    <Footer />
  </main>
);

export default Layout;
